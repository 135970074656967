import React from 'react';
import { asserts } from 'utility/asserts';

export type ChannelSelectionContext = {
  checked: (channel: keyof CheckedChannelsState) => boolean;
  toggleChecked: (channel: keyof CheckedChannelsState) => void;
};

export type CheckedChannelsState = {
  email: boolean;
  notificationCenter: boolean;
  push: boolean;
};

type UseChannelSelectionStateOptions = {
  initialCheckedChannels: CheckedChannelsState;
};

type ChannelSelectionStateReturn = {
  /**
   * Check if a channel is in a checked state
   */
  checked: (channel: keyof CheckedChannelsState) => boolean;
  /**
   * Toggle the checked state of a channel
   */
  toggleChecked: (channel: keyof CheckedChannelsState) => boolean;
};

export function useChannelSelectionState({
  initialCheckedChannels,
}: UseChannelSelectionStateOptions): ChannelSelectionStateReturn {
  const [checkedChannels, setCheckedChannels] = React.useState(
    initialCheckedChannels
  );

  function toggleChecked(channel: keyof CheckedChannelsState) {
    setCheckedChannels((prev) => {
      return {
        ...prev,
        [channel]: !prev[channel],
      };
    });
    return checkedChannels[channel];
  }

  function checked(channel: keyof CheckedChannelsState) {
    return checkedChannels[channel];
  }

  return {
    checked,
    toggleChecked,
  };
}

export const ChannelSelectionContext = React.createContext<ChannelSelectionContext | null>(
  null
);
export function useChannelSelectionContext(): ChannelSelectionContext {
  const context = React.useContext<ChannelSelectionContext | null>(
    ChannelSelectionContext
  );
  asserts(
    context !== null,
    'useChannelSelection must be used within a ChannelSelectionProvider'
  );
  return context;
}
