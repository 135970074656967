import { Button } from 'DesignSystem/Form';
import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { JourneyTemplateListItem } from 'models/journeys/journey';
import styles from './journey-list-page.module.css';

export const JourneyTemplateBanner: React.FC<{
  template: JourneyTemplateListItem;
}> = ({ template }) => {
  const buttonHref = `../new/edit?template_id=${template.id}`;

  return (
    <Button
      layoutOnly
      href={buttonHref}
      className={styles.templateButton}
      key={template.id}
      label={
        <Flex start className={styles.templateInfo} column>
          <div className="line-clamp-2">{template.name}</div>
          <div>Created by Firstup</div>
          {template.updatedAt && (
            <div>Last edited {template.updatedAt.toFormat('MM/dd/yyyy')}</div>
          )}
        </Flex>
      }
    />
  );
};
