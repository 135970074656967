import camelcaseKeys from 'camelcase-keys';
import { logError } from 'hooks/datadog';
import { Step } from 'models/journeys/journey';
import {
  BaseStepError,
  CommunicationStepErrors,
  JourneyErrors,
  StepErrors,
} from 'models/journeys/journey-errors';

const serverStartStepErrorTypes = [
  'root_step_id',
  'trigger',
  'trigger_criterion',
] as const;
type ServerStartStepErrorTypes = typeof serverStartStepErrorTypes[number];
type ServerStartStepErrors = BaseStepError<ServerStartStepErrorTypes>;

const serverEmailChannelErrorTypes = ['address', 'subject', 'preview'] as const;
type ServerEmailChannelErrorTypes = typeof serverEmailChannelErrorTypes[number];
type ServerEmailChannelErrors = BaseStepError<ServerEmailChannelErrorTypes>;

const serverCommunicationErrorTypes = [
  'title',
  'design_id',
  'design_processing_state',
] as const;
type ServerCommunicationStepErrorTypes = typeof serverCommunicationErrorTypes[number];
type ServerCommunicationStepErrors = BaseStepError<
  ServerCommunicationStepErrorTypes
> & {
  email_channel?: ServerEmailChannelErrors;
};

const serverDecisionStepErrorTypes = ['order', 'criterion'] as const;
type ServerDecisionStepErrorTypes = typeof serverDecisionStepErrorTypes[number];
type ServerDecisionStepErrors = BaseStepError<ServerDecisionStepErrorTypes>;

const serverDelayStepErrorTypes = ['unit', 'quantity'] as const;
type ServerDelayStepErrorTypes = typeof serverDelayStepErrorTypes[number];
type ServerDelayStepErrors = BaseStepError<ServerDelayStepErrorTypes>;

type ServerStepErrors =
  | ServerStartStepErrors
  | ServerCommunicationStepErrors
  | ServerDecisionStepErrors
  | ServerDelayStepErrors;

type ServerJourneyGraphErrors =
  | ['initiation', ServerStartStepErrors]
  | [
      'steps',
      Record<Step['id'], Exclude<ServerStepErrors, ServerStartStepErrors>>
    ];

const isServerCommunicationStepError = (
  errors: unknown
): errors is ServerCommunicationStepErrors =>
  !!errors &&
  !Object.keys(errors as ServerCommunicationStepErrors).some(
    (key) =>
      !(
        serverCommunicationErrorTypes.includes(
          key as ServerCommunicationStepErrorTypes
        ) || key === 'email_channel'
      )
  );

const isServerStartStepError = (
  errors: unknown
): errors is ServerStartStepErrors =>
  !!errors &&
  !Object.keys(errors as ServerStartStepErrors).some(
    (key) =>
      !serverStartStepErrorTypes.includes(key as ServerStartStepErrorTypes)
  );

export const deserializeJourneyErrors = (
  error: Error,
  startStepId = 'initiation'
): JourneyErrors => {
  try {
    const parsedErrors: ServerJourneyGraphErrors[] = JSON.parse(error.message)
      .errors;

    return parsedErrors.reduce((journeyErrors: JourneyErrors, graphErrors) => {
      const [errorKey, stepErrors] = graphErrors;

      if (errorKey === 'initiation') {
        return {
          ...journeyErrors,
          graph: {
            ...journeyErrors.graph,
            [startStepId]: deserializeStepErrors(stepErrors),
          },
        };
      }

      if (errorKey === 'steps') {
        return {
          ...journeyErrors,
          graph: {
            ...journeyErrors.graph,
            ...Object.keys(stepErrors).reduce(
              (errors, stepId) => ({
                ...errors,
                [stepId]: deserializeStepErrors(stepErrors[stepId]),
              }),
              {}
            ),
          },
        };
      }

      return journeyErrors;
    }, {});
  } catch (e) {
    if (e instanceof Error)
      logError(new Error('Unable to parse journey validation errors.'), {
        input: error,
        errorMessage: e.message,
      });
    return {};
  }
};

const deserializeStepErrors = (stepErrors: ServerStepErrors): StepErrors => {
  if (isServerStartStepError(stepErrors)) {
    if (stepErrors.trigger) {
      // eslint-disable-next-line no-param-reassign
      stepErrors.trigger = ['Start Type is required'];
    }
    return camelcaseKeys(stepErrors);
  }

  if (isServerCommunicationStepError(stepErrors)) {
    const { email_channel: emailChannel, ...rest } = stepErrors;
    let emailChannelErrors = {};
    if (emailChannel) {
      emailChannelErrors = Object.keys(emailChannel).reduce(
        (errors: CommunicationStepErrors, channelKey) => ({
          ...errors,
          [`emailChannel_${channelKey}`]: emailChannel[
            channelKey as ServerEmailChannelErrorTypes
          ],
        }),
        {}
      );
    }

    return camelcaseKeys({
      ...rest,
      ...emailChannelErrors,
    });
  }

  return camelcaseKeys(stepErrors);
};
