import * as React from 'react';
import cx from 'classnames';
import {
  BooleanFilter,
  FiltersStateType,
  DateFilter,
  ContentFiltersContext,
} from 'contexts/content/filters';
import { FilterLayoutProvider } from 'contexts/content/filter-layout';
import { usePermissions } from 'contexts/permissions';
import { Close } from 'shared/icons';
import filtersStyles from 'DesignSystem/Table/filters.module.css';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import { FilterDropdown } from './FilterDropdown';
import { NewFiltersButton } from './NewFiltersButton';
import styles from './filters.module.css';
import { SourcesFilter } from './SourcesFilter';

type FilterProps = {
  filters: FiltersStateType;
  setValue: (name: string, values: string[]) => void;
  setBooleanValue: (name: string, value: boolean) => void;
  dropdownAlignment?: 'left' | 'right' | 'center';
  layout?: 'campaigns' | 'journeys';
};

export const Filters: React.FC<FilterProps> = ({
  children,
  filters,
  setValue,
  setBooleanValue,
  dropdownAlignment,
  layout = 'campaigns',
}) => {
  const { setFilterSelected } = React.useContext(ContentFiltersContext);
  const {
    permissions: { canFilterByAuthor },
  } = usePermissions();
  const { id: programId } = useProgram();
  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;

  const handleFilter = React.useCallback(
    (name, value) => {
      setValue(name, value);
    },
    [setValue]
  );

  const handleBooleanFilter = React.useCallback(
    (name, value) => {
      setBooleanValue(name, value);
    },
    [setBooleanValue]
  );

  const handleDateFilter = React.useCallback(
    (name, value) => {
      setFilterSelected(name, value);
    },
    [setFilterSelected]
  );

  const hiddenFilters = (filter: string) => {
    switch (filter) {
      case 'publishers':
        return canFilterByAuthor && !useAuthorAliases;
      case 'creators':
        // creators filter is only visible if author aliases are enabled
        return canFilterByAuthor && useAuthorAliases;
      case 'author_aliases':
        return canFilterByAuthor && useAuthorAliases;
      case 'flaggedEntities':
        return layout === 'campaigns';
      default:
        return true;
    }
  };

  const keys = Object.keys(filters.boolean);

  const selectedFilters = keys
    .filter((k) => filters.boolean[k].value)
    .map((k) => filters.boolean[k]);
  const unselectedDateFilters = Object.keys(filters.date)
    .filter((f) => !filters.date[f].isSelected)
    .map((k) => filters.date[k]);
  const unselectedBooleanFilters = keys
    .filter((k) => !filters.boolean[k].value)
    .map((k) => filters.boolean[k]);
  const unselectedFilters = [
    ...unselectedBooleanFilters,
    ...unselectedDateFilters,
  ];
  const visibleDateFilters = Object.keys(filters.date).filter(
    (f) => filters.date[f].isSelected
  );
  const visibleBooleanFilters = Object.keys(filters.standard)
    .filter((f) => filters.standard[f].isVisible)
    .filter(hiddenFilters);
  const visibleFilters = [...visibleDateFilters, ...visibleBooleanFilters];
  const closeSourcesFitler = React.useCallback(() => {
    handleBooleanFilter('sources', false);
  }, [handleBooleanFilter]);

  const triggerClassNames = React.useMemo(
    () => ({
      customTrigger: styles.booleanTrigger,
      filterTrigger: filtersStyles.FilterTrigger,
      appliedTrigger: filtersStyles.FilterTriggerApplied,
      closeButton: filtersStyles.FilterTriggerCloseButton,
    }),
    []
  );

  const topRow = (
    <>
      <div>{children}</div>
      {unselectedFilters?.length > 0 && (
        <Box padding={layout === 'campaigns' ? [0, 12, 0, 0] : undefined}>
          <NewFiltersButton
            layout={layout}
            filters={unselectedFilters}
            onSelectFilter={(filter) =>
              (filter as DateFilter).type === 'date'
                ? handleDateFilter(filter.name, true)
                : handleBooleanFilter(filter.name, true)
            }
          />
        </Box>
      )}
    </>
  );

  return (
    <>
      <div className={styles.filtersWrapper}>
        {layout === 'journeys' ? (
          <Flex className={styles.topRowWrapper}>{topRow}</Flex>
        ) : (
          topRow
        )}
        {selectedFilters
          .filter((f) => f.name === 'sources')
          .map(
            (f: BooleanFilter) =>
              f.name === 'sources' && (
                <SourcesFilter
                  key="sourcesFilter"
                  closeFilter={closeSourcesFitler}
                  onChange={handleFilter}
                  filters={filters}
                  align={dropdownAlignment}
                />
              )
          )}
        {visibleFilters.map((k) => (
          <FilterLayoutProvider layout={layout}>
            <FilterDropdown
              setFilterValues={handleFilter}
              key={k}
              filter={filters.standard[k] || filters.date[k]}
              dropdownAlignment={dropdownAlignment}
            />
          </FilterLayoutProvider>
        ))}
        {selectedFilters
          .filter((f) => f.name !== 'sources')
          .map((f: BooleanFilter) => (
            <div
              key={f.name}
              className={cx(
                triggerClassNames.filterTrigger,
                triggerClassNames.appliedTrigger,
                triggerClassNames.customTrigger
              )}
            >
              <div>{f.label}</div>
              <button
                className={triggerClassNames.closeButton}
                onClick={() => handleBooleanFilter(f.name, false)}
                type="button"
              >
                <Close />
              </button>
            </div>
          ))}
      </div>
    </>
  );
};
