import React from 'react';
import cx from 'classnames';
import { scaleOrdinal } from 'd3';
import { CampaignResultsData } from '../../../../../../../services/api-insights';
import { PieChart } from '../../../../../../../shared/Charts/PieChart';
import styles from '../MetricsCards.module.css';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface IContentSourceCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const dimensions = {
  width: 528,
  height: 241,
  padding: {
    top: 0,
    bottom: 0,
    right: 20,
    left: 20,
  },
  pie: {
    width: 200,
    height: 200,
    padding: {
      top: 20,
      bottom: 20,
      right: 20,
      left: 20,
    },
    gap: 0.02,
  },
};

export const ContentSourceCard: React.FC<IContentSourceCard> = ({
  data,
  isLoading,
}) => {
  const openedPush = data?.campaign.engagement.opened_push;
  const openedEmail = data?.campaign.engagement.opened_email;
  const openedExperience = data?.campaign.engagement.opened_experience;
  const graphData = [
    {
      label: 'Opened Email',
      value: openedEmail,
    },
    {
      label: 'Opened Push',
      value: openedPush,
    },
    {
      label: 'Opened Experience',
      value: openedExperience,
    },
  ];

  const color = scaleOrdinal()
    .domain(graphData.map((d) => d.label))
    .range([
      'url(#orangeBarGradient)',
      'url(#greenBarGradient)',
      'url(#blueBarGradient)',
    ]);

  const drawCustom = () => {
    return (
      <defs>
        <linearGradient
          id="orangeBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(245, 158, 11, 1)" />
          <stop offset="100%" stopColor="rgba(255, 208, 130, 0.93)" />
        </linearGradient>
        <linearGradient
          id="yellowBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(254, 209, 94, 1)" />
          <stop offset="100%" stopColor="rgba(255, 232, 174, 1)" />
        </linearGradient>
        <linearGradient
          id="greenBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(182, 228, 109, 1)" />
          <stop offset="100%" stopColor="rgba(219, 252, 166, 1)" />
        </linearGradient>
        <linearGradient
          id="blueBarGradient"
          x1="0%"
          x2="100%"
          y1="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgba(112, 214, 236, 1)" />
          <stop offset="100%" stopColor="rgba(182, 242, 255, 1)" />
        </linearGradient>
      </defs>
    );
  };

  return (
    <div className={cx(styles.metricCard, styles.metricCardHalf)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>
          How were Users <b>first</b> brought to your content
        </h1>
      </div>
      {isLoading ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={dimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <PieChart
            data={graphData}
            customColors={color}
            customDrawing={drawCustom}
            chartDimensions={dimensions}
          />
        </div>
      )}
      <div className={styles.metricCardBody}>
        <p>
          <i>*Statistics are based on impression and email open timestamps</i>
        </p>
      </div>
    </div>
  );
};
