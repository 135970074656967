import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Label } from 'models/publisher/settings';
import { useMemo } from 'react';

export type EngagementFlags = {
  [key: string]: EngagementFlag;
};
export type EngagementFlag = {
  title: string;
  description: string;
  available: boolean;
  value: boolean;
  disabled?: boolean;
};

export const useEngagementToggles = (): {
  engagementFlags: EngagementFlags;
  showSharableData: boolean;
  onEngagementTogglesChange: (
    flags: EngagementFlags,
    slug: string,
    shareMessage: string,
    acknowledgementLabel: Label | undefined
  ) => unknown;
} => {
  const {
    contentTranslationsEnabled,
    smartCampaignRequiredAllTypesEnabled,
    commentingEnabled,
    contentSharingEnabled,
  } = useFeatureFlags();
  const {
    settings,
    settings: { isShareable, isCommentable, isTranslatable, acknowledge },
  } = useSettings();

  const onEngagementTogglesChange = (
    flags: EngagementFlags,
    slug: string,
    shareMessage: string,
    acknowledgementLabel: Label | undefined
  ) => {
    const flagsPayload = Object.keys(flags).reduce((res, name) => {
      res[name] = flags[name].value;
      return res;
    }, {} as { [key: string]: boolean });
    return { ...flagsPayload, slug, shareMessage, acknowledgementLabel };
  };

  const showSharableData = useMemo(
    () =>
      isShareable &&
      settings.slug?.length > 0 &&
      settings.shareMessage?.length > 0,
    [isShareable, settings.slug, settings.shareMessage]
  );

  const engagementFlags = {
    isShareable: {
      title: 'External Sharing',
      description: 'Allow shares externally',
      available: !!contentSharingEnabled,
      value: isShareable,
      disabled: acknowledge,
    },
    isCommentable: {
      title: 'Commenting',
      description: 'Allow comments',
      available: !!commentingEnabled,
      value: isCommentable,
    },
    isTranslatable: {
      title: 'Translation',
      description: 'Allow translation',
      available: !!contentTranslationsEnabled,
      value: isTranslatable,
    },
    acknowledge: {
      title: 'Acknowledge',
      description: 'Require acknowledgement',
      available: !!smartCampaignRequiredAllTypesEnabled,
      value: acknowledge,
      disabled: isShareable,
    },
  } as EngagementFlags;

  return {
    engagementFlags,
    showSharableData,
    onEngagementTogglesChange,
  };
};
