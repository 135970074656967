import { Filter, transformJsonToFilter } from 'models/insight/Filter';
import {
  ApiDataJson,
  ApiGetDataJson,
  ApiGetIndexDataJson,
} from 'models/insight/json/baseApiJson';
import { BookmarkedReportJson } from 'models/insight/json/bookmarkedReportJson';
import { CollectionJson } from 'models/insight/json/collectionJson';
import { FilterJson, Option } from 'models/insight/json/filterJson';
import { MetabaseReportJson } from 'models/insight/json/reportJson';
import { ReportDownload } from 'models/report-download';
import {
  bossanovaDomain,
  prepareQueryString,
  request,
} from 'services/api-shared';
import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import { LineData } from '../shared/Charts/LineChart';

export type ProgramIdProp = {
  programId: number;
};

export type ReportFetchProps = ProgramIdProp & {
  reportId: number | string | undefined;
};

export type DojoFilterFetchProps = ReportFetchProps & {
  filterSlug: string;
  dateRangeParam?: string;
};

export type CampaignFilterFetchProps = ReportFetchProps & {
  page: number;
  search?: string;
  dateRange: string | undefined | false; // if no date range, empty string is defaulted, false is ignore
  // in the back end this is interpreted as "past30days"
};

export type PollFilterFetchProps = ReportFetchProps & {
  page: number;
  search?: string;
  dateRange: string | undefined | false; // if no date range, empty string is defaulted, false is ignore
  // in the back end this is interpreted as "past30days"
};

export type AttributeFilterFetchProps = ReportFetchProps & {
  page: number;
  attribute_type: string;
  search?: string;
  dateRange: string | undefined | false; // if no date range, empty string is defaulted, false is ignore
  // in the back end this is interpreted as "past30days"
};

export type ContentFilterFetchProps = ReportFetchProps & {
  page: number;
  filterSlug: string; // slug can be `content` or `content_id`, we must pass the actual slug
  // if we assume one or the other, a mismatch in dojo will cause a 500 error
  search?: string;
  dateRange: string | undefined | false; // if no date range, empty string is defaulted, false is ignore
  // in the back end this is interpreted as "past30days"
  ids?: number[];
};

export type ReportLikeProps = ReportFetchProps & {
  like: boolean;
};

// represents the types of values available for internal states of filters
// TODO: we should combine FilterStateObj and FilterStateWithSingleValuesObj
// we currently separate them for backwards compatibility
// the report download functionality expects that all values be arrays
// once we make that more robust we can combine these and clean up some duplicate code
export type FilterValue = string | number | boolean | null | undefined;
export type FilterStateObj = {
  [key: string]: FilterValue[] | null;
};
export type FilterStateWithSingleValuesObj = {
  [key: string]: FilterValue[] | FilterValue | null;
};

type ReportBookmarkProps = ReportFetchProps & {
  title: string;
  filterState: FilterStateObj;
  public: boolean;
};

// get a new metabase embed url after filter update
export type MetabaseEmbedUrlProps = ReportFetchProps & {
  filterState: FilterStateWithSingleValuesObj;
};
export type MetabaseEmbedUrlResponse = ApiDataJson<{ embed_src: string }>;

// -------------------------------
const insightsApiDomain = (programId: number, endPath: string): string =>
  `${bossanovaDomain}/api/v1/programs/${programId}/insights/${endPath}`;

const fetchInsightsApi = async <TResponse>(
  programId: number,
  endPath: string,
  method = 'GET',
  body?: string | FormData
): Promise<TResponse> => {
  const url = insightsApiDomain(programId, endPath);
  const response = await request(url, { body, method });
  if (response.status >= 200) return response.json();
  throw new Error(`Error Fetching ${method} ${url}`);
};

export const fetchTableauToken = async (programId: number): Promise<string> => {
  return (await fetchGet<{ token: string }>(programId, 'token')).token;
};

export const fetchTableauWorkbooks = async (
  programId: number
): Promise<Tableau.Workbook[]> => {
  return (await fetchGet<{ workbooks: never[] }>(programId, 'workbooks'))
    .workbooks;
};
export const fetchTableauWorkbook = async (
  programId: number,
  workbookId: string
): Promise<Tableau.Workbook> => {
  return (
    await fetchGet<{ workbook: never }>(programId, `workbooks/${workbookId}`)
  ).workbook;
};

export const fetchTableauWorkbookCategories = async (
  programId: number
): Promise<Tableau.WorkbookCategory[]> => {
  return (
    await fetchGet<{ data: Tableau.WorkbookCategory[] }>(
      programId,
      'categories'
    )
  ).data;
};

export const fetchTableauWorkbookDashboards = async (
  programId: number,
  workbookId: string
): Promise<Tableau.Dashboard[]> => {
  return (
    await fetchGet<{ dashboards: never[] }>(
      programId,
      `workbooks/${workbookId}/dashboards`
    )
  ).dashboards;
};

export const fetchTableauWorkbookPreviewImage = async (
  programId: number,
  workbookId: string
): Promise<string> => {
  return (
    await fetchGet<{ previewImage: string }>(
      programId,
      `workbooks/${workbookId}/preview_image`
    )
  ).previewImage;
};

export const fetchTableauDashboards = async (
  programId: number
): Promise<Tableau.Dashboard[]> => {
  return (await fetchGet<{ dashboards: never[] }>(programId, 'dashboards'))
    .dashboards;
};

export const fetchTableauDashboard = async (
  programId: number,
  dashboardId: string
): Promise<Tableau.Dashboard> => {
  return (
    await fetchGet<{ dashboard: never }>(programId, `dashboard/${dashboardId}`)
  ).dashboard;
};
export const fetchDashboardPdf = async (
  programId: number,
  dashboardId: string
): Promise<Blob> => {
  const url = `${bossanovaDomain}/api/v1/programs/${programId}/insights/download-view/${dashboardId}`;
  const response = await request(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/pdf' },
  });
  if (response.status >= 200) return response.blob();
  throw new Error('Error downloading pdf.');
};

export const auditReportDownload = async <TResponse>(
  token: string
): Promise<TResponse> => {
  const url = `${bossanovaDomain}/api/v1/insights/reports/audit_download/${token}`;
  const response = await request(url, { method: 'POST' });
  if (response.status >= 200) return response.json();
  throw new Error(`Error auditing report download`);
};

export const fetchDownloads = async (
  programId: number
): Promise<ReportDownload[]> => {
  const { data } = await fetchInsightsApi<{
    data: Array<{
      id: string;
      attributes: Pick<ReportDownload, 'name' | 'date_range'>;
    }>;
  }>(programId, 'downloads');
  const urlPrefix = insightsApiDomain(programId, 'downloads');
  return data.map((row) => ({
    id: row.id,
    name: row.attributes.name,
    url: `${urlPrefix}/${row.id}`,
    date_range: row.attributes.date_range,
    status: 'locked',
  }));
};

export const fetchPresignedDownload = async (
  programId: number,
  reportId: string
): Promise<Pick<ReportDownload, 'id' | 'url'> | undefined> => {
  const json = (
    await fetchInsightsApi<{
      data: {
        id: string;
        attributes: Pick<ReportDownload, 'url'>;
      };
    }>(programId, `downloads/${reportId}`, 'POST')
  ).data;
  if (!json) return undefined;
  return {
    id: json.id,
    url: json.attributes.url,
  };
};

// generic GET to fetch a single resource
export const fetchGet = async <T>(
  programId: number,
  endPath: string
): Promise<T> => {
  return fetchInsightsApi<T>(programId, endPath);
};

// generic GET to fetch an index of resources
export const fetchGetIndex = async <T>(
  programId: number,
  endPath: string
): Promise<ApiGetIndexDataJson<T>> => {
  return fetchInsightsApi<ApiGetIndexDataJson<T>>(programId, endPath);
};

// generic POST request
export const fetchPost = async (
  programId: number,
  endPath: string,
  body?: string
): Promise<MetabaseEmbedUrlResponse> => {
  return fetchInsightsApi<MetabaseEmbedUrlResponse>(
    programId,
    endPath,
    'POST',
    body
  );
};

// Returns the overview report
export const fetchOverviewReport = async (
  props: ProgramIdProp
): Promise<ApiGetDataJson<MetabaseReportJson>> => {
  return fetchGet(props.programId, 'reports/new-studio-analyze-featured');
};

export const fetchUpdatedMetabaseUrl = async (
  props: MetabaseEmbedUrlProps
): Promise<MetabaseEmbedUrlResponse> => {
  const endPath = `reports/${props.reportId}/embed_onfirstup`;
  const updatedFilterState: FilterStateWithSingleValuesObj = {};
  Object.keys(props.filterState).forEach((key) => {
    if (props.filterState[key] !== null)
      updatedFilterState[key] = props.filterState[key];
  });
  const body = JSON.stringify(updatedFilterState);
  return fetchPost(props.programId, endPath, body);
};

// fetch details for single report (including metabase embed url)
export const fetchReportDetails = async (
  props: ReportFetchProps
): Promise<ApiGetDataJson<MetabaseReportJson>> => {
  return fetchGet(props.programId, `reports/${props.reportId}`);
};

// Fetch Filter Details from Dojo API
export const fetchDojoFilterDetails = async ({
  programId,
  reportId,
  filterSlug,
  dateRangeParam,
}: DojoFilterFetchProps): Promise<ApiDataJson<FilterJson>> => {
  const dateRange = dateRangeParam ? `date_range=${dateRangeParam}` : '';
  const endPath = `reports/${reportId}/filters/${filterSlug}?${dateRange}`;
  return fetchGet<ApiDataJson<FilterJson>>(programId, endPath);
};
// Fetch Filter Details from Dojo API
export const fetchAndTransformDojoFilters = async (
  apiProps: DojoFilterFetchProps
): Promise<Filter> => {
  const filterJson: ApiDataJson<FilterJson> = await fetchDojoFilterDetails(
    apiProps
  );
  return transformJsonToFilter(filterJson.data);
};

export const fetchAttributeFilterDetails = async ({
  programId,
  reportId,
  search,
  attribute_type,
  page = 1,
  dateRange = '',
}: AttributeFilterFetchProps): Promise<ApiDataJson<FilterJson>> => {
  let endPath = `reports/${reportId}/filters/${attribute_type}?date_range=${dateRange}&page=${page}`;
  if (search) endPath += `&search=${encodeURIComponent(search)}`;
  return fetchGet<ApiDataJson<FilterJson>>(programId, endPath);
};
export const fetchAndTransformAttributeFilters = async (
  apiProps: AttributeFilterFetchProps
): Promise<Option[]> => {
  const filterJson: ApiDataJson<FilterJson> = await fetchAttributeFilterDetails(
    apiProps
  );
  return transformJsonToFilter(filterJson.data).values || [];
};

// Fetch Campaign filter details. same endpoint as dojo filters but with more params
export const fetchCampaignFilterDetails = async ({
  programId,
  reportId,
  search,
  page = 1,
  dateRange = '',
}: CampaignFilterFetchProps): Promise<ApiDataJson<FilterJson>> => {
  let endPath = `reports/${reportId}/filters/campaign?date_range=${dateRange}&page=${page}`;
  if (search) endPath += `&search=${encodeURIComponent(search)}`;
  return fetchGet<ApiDataJson<FilterJson>>(programId, endPath);
};
export const fetchAndTransformCampaignFilters = async (
  apiProps: CampaignFilterFetchProps
): Promise<Option[]> => {
  const filterJson: ApiDataJson<FilterJson> = await fetchCampaignFilterDetails(
    apiProps
  );
  return transformJsonToFilter(filterJson.data).values || [];
};

export const fetchPollFilterDetails = async ({
  programId,
  reportId,
  search,
  page = 1,
  dateRange = '',
}: PollFilterFetchProps): Promise<ApiDataJson<FilterJson>> => {
  let endPath = `reports/${reportId}/filters/poll?date_range=${dateRange}&page=${page}`;
  if (search) endPath += `&search=${encodeURIComponent(search)}`;
  return fetchGet<ApiDataJson<FilterJson>>(programId, endPath);
};

export const fetchAndTransformPollFilters = async (
  apiProps: PollFilterFetchProps
): Promise<Option[]> => {
  const filterJson: ApiDataJson<FilterJson> = await fetchPollFilterDetails(
    apiProps
  );
  return transformJsonToFilter(filterJson.data).values || [];
};

// Fetch Content filter details. same endpoint as dojo filters but with more params
export const fetchContentFilterDetails = async ({
  programId,
  reportId,
  filterSlug,
  search,
  page = 1,
  dateRange = '',
  ...queryParams
}: ContentFilterFetchProps): Promise<ApiDataJson<FilterJson>> => {
  const queryString = prepareQueryString(queryParams);
  let endPath = `reports/${reportId}/filters/${filterSlug}?date_range=${dateRange}&page=${page}`;
  if (search) endPath += `&search=${encodeURIComponent(search)}`;
  if (Object.keys(queryParams).length) endPath += `&${queryString}`;
  return fetchGet<ApiDataJson<FilterJson>>(programId, endPath);
};
export const fetchAndTransformContentFilters = async (
  apiProps: ContentFilterFetchProps
): Promise<Option[]> => {
  const filterJson: ApiDataJson<FilterJson> = await fetchContentFilterDetails(
    apiProps
  );
  return transformJsonToFilter(filterJson.data).values || [];
};

// fetch related reports for a report
export const fetchRelatedReports = async (
  props: ReportFetchProps
): Promise<ApiGetIndexDataJson<MetabaseReportJson>> => {
  return fetchGetIndex(props.programId, `reports/${props.reportId}/related`);
};

// Fetch Bookmarked reports
export const fetchBookmarkedCollection = async (
  props: ProgramIdProp
): Promise<ApiGetIndexDataJson<BookmarkedReportJson>> => {
  return fetchGetIndex(props.programId, 'bookmarked_reports');
};

// Fetch Bookmarked reports
export const fetchCustomCollections = async (
  props: ProgramIdProp
): Promise<ApiGetIndexDataJson<CollectionJson>> => {
  return fetchGetIndex(props.programId, 'report_collections');
};

// Like a report
export const likeReport = async (props: ReportLikeProps): Promise<Response> => {
  const url = insightsApiDomain(
    props.programId,
    `reports/${props.reportId}/reactions/like`
  );
  return request(url, { method: props.like ? 'POST' : 'DELETE' });
};

// Bookmark a report
export const addReportBookmark = async (
  props: ReportBookmarkProps
): Promise<Response | void> => {
  const { programId, reportId, title, filterState: filter_state } = props;
  const url = insightsApiDomain(
    programId,
    `reports/${reportId}/user_report_bookmarks`
  );
  const body = JSON.stringify({ title, filter_state, public: props.public });

  const response = await (
    await request(url, {
      body,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
  ).json();

  return response;
};

// Delete a bookmark
export const deleteReportBookmark = async (
  props: ReportFetchProps & { id: number }
): Promise<Response> => {
  const url = insightsApiDomain(
    props.programId,
    `reports/${props.reportId}/user_report_bookmarks/${props.id}`
  );

  return request(url, { method: 'DELETE' });
};

interface CampaignResultsFetchProps {
  programId: number;
  contentId: number;
  query: string;
}

export type PerformanceData = {
  email: number;
  push: number;
  assistant: number;
  experience: number;
};

export type CampaignResultsData = {
  campaign: {
    stats: { [key: string]: number };
    engagement: { [key: string]: number };
    email_engagement: { [key: string]: number };
    performance_trend: Array<LineData>;
    performance: {
      delivered: PerformanceData;
      previewed: PerformanceData;
      opened: PerformanceData;
      conversionRate?: PerformanceData;
    };
    open_timeframes: {
      [key: string]: {
        [key: string]: number;
      };
    };
    email_open_timeframes: {
      [key: string]: {
        [key: string]: number;
      };
    };
  };
  calculated_at: string;
};

export const fetchCampaignResultsData = async ({
  programId,
  contentId,
  query,
}: CampaignResultsFetchProps): Promise<ApiDataJson<CampaignResultsData>> => {
  let endPath = `campaign/${contentId}/summary_data`;
  if (query.length) endPath += `?query=${query}`;
  return fetchGet<ApiDataJson<CampaignResultsData>>(programId, endPath);
};

export type UserActivityStreamData = {
  id: number;
  user_name: string;
  email: string;
  channel: string;
  last_previewed: Date | null;
  last_opened: Date | null;
  last_engaged: Date | null;
  last_error: Date | null;
  last_activity: Date | null;
  last_email_opened: Date | null;
  last_email_delivered: Date | null;
  last_email_clicked: Date | null;
  last_subscription_status_event: Date | null;
};

export type FetchUserActivityStreamDataProps = {
  programId: number;
  contentId: number;
  channel?: 'Email' | undefined;
  page: number;
  pageSize: number;
  sortBy: string;
  sortDirection: string;
  search?: string | undefined;
};
export const fetchUserActivityStreamData = async (
  props: FetchUserActivityStreamDataProps
): Promise<ApiDataJson<UserActivityStreamData[]>> => {
  const { contentId, search, channel, ...queryParams } = props;
  const query = qs.stringify(snakeCaseKeys({ ...queryParams }));
  let endPath = `campaign/${contentId}/user_activity?${query}`;
  if (search) endPath += `&search=${encodeURIComponent(search)}`;
  if (channel) endPath += `&channel=${encodeURIComponent(channel)}`;
  return fetchGet<ApiDataJson<UserActivityStreamData[]>>(
    props.programId,
    endPath
  );
};
