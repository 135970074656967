import {
  Checkbox,
  CheckboxLabel,
  Icon,
} from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Text } from 'DesignSystem/Typography';
import { ProgramOrAuthor } from 'hooks/useAuthorsList';
import { useUniqueId } from 'hooks/useUniqueId';
import { EmailSenderAlias } from 'models/publisher/settings';
import React from 'react';
import {
  AuthorCombobox,
  EmailSenderAliasCombobox,
  TextInputField,
} from './inputs';
import styles from './channel-selection.module.css';
import {
  ChannelSelectionContext,
  CheckedChannelsState,
  useChannelSelectionContext,
} from './hooks';

export const ChannelSelection = Object.assign(ChannelSelectionRoot, {
  Email: EmailChannel,
  NotificationCenter: NotificationCenterChannel,
});

type ChannelSelectionProps = {
  children: React.ReactNode;
} & Pick<ChannelSelectionContext, 'checked' | 'toggleChecked'>;

export function ChannelSelectionRoot({
  children,
  ...contextValues
}: ChannelSelectionProps): JSX.Element {
  return (
    <section>
      <div className={styles.channelSelectionHeader}>
        <Text
          className={{
            Subheading: true,
            Semibold: true,
          }}
        >
          Channels
        </Text>
      </div>
      <ChannelSelectionContext.Provider value={contextValues}>
        <div className={styles.channelSelectionGroups}>{children}</div>
      </ChannelSelectionContext.Provider>
    </section>
  );
}

type EmailGroupProps = {
  emailAlias: EmailSenderAlias | undefined;
  onEmailAliasChange: (alias: EmailSenderAlias) => void;

  subject: string;
  onSubjectChange: (subject: string) => void;

  previewText: string;
  onPreviewTextChange: (previewText: string) => void;

  errors?: {
    emailAlias: string[];
    subject: string[];
    previewText: string[];
  };
};

function EmailChannel({
  errors,
  onEmailAliasChange,
  emailAlias,
  onSubjectChange,
  subject,
  onPreviewTextChange,
  previewText,
}: EmailGroupProps) {
  return (
    <CheckboxGroup
      label="Email"
      name="email"
      icon={<Icon size={24}>mail</Icon>}
    >
      <EmailSenderAliasCombobox
        emailAlias={emailAlias}
        onAliasSelect={onEmailAliasChange}
      />

      <TextInputField
        label="Subject Line"
        value={subject}
        onChange={onSubjectChange}
        placeholder="Notification Placeholder"
        errorMessage={errors?.subject?.join(', ')}
        /**
         * AI Generation Feature Required for Delivery page implementation
         */
        // post={post}
        // showAIGenerationButton={usingAIGeneration}
        // aiCommandType="delivery_preview"
        // context={{ subject: localNotification.text }}
      />

      <TextInputField
        label="Preview"
        value={previewText}
        onChange={onPreviewTextChange}
        placeholder="Notification Placeholder"
        errorMessage={errors?.previewText?.join(', ')}
        /**
         * AI Generation Feature Required for Delivery page implementation
         */
        // post={post}
        // showAIGenerationButton={usingAIGeneration}
        // aiCommandType="delivery_preview"
        // context={{ subject: localNotification.text }}
      />
    </CheckboxGroup>
  );
}

type NotificationGroupProps = {
  title: string;
  onTitleChange: (title: string) => void;

  pushMessage: string;
  onPushMessageChange: (message: string) => void;

  markedAsImportant: boolean;
  onMarkedAsImportantChange: (important: boolean) => void;

  errors?: {
    title: string[];
    pushMessage: string[];
  };
};

function NotificationCenterChannel({
  title,
  onTitleChange,
  pushMessage,
  onPushMessageChange,
  markedAsImportant,
  onMarkedAsImportantChange,
  errors,
}: NotificationGroupProps) {
  const [author, setAuthor] = React.useState<ProgramOrAuthor | undefined>(
    undefined
  );

  const markedImportantCheckboxId = useUniqueId();
  const markedImportantLabelId = `${markedImportantCheckboxId}__label`;
  const markedImportantDescriberId = `${markedImportantCheckboxId}__describer`;
  return (
    <CheckboxGroup
      label="Notification Center"
      name="notificationCenter"
      icon={<Icon size={24}>notifications</Icon>}
    >
      <AuthorCombobox onAuthorSelect={setAuthor} author={author} />

      <TextInputField
        label="Title"
        value={title}
        onChange={onTitleChange}
        placeholder="Notification Placeholder"
        errorMessage={errors?.title?.join(', ')}
      />

      <CheckboxGroup
        label="Send a Push Notification"
        name="push"
        icon={<Icon size={24}>phone_iphone</Icon>}
      >
        <TextInputField
          label="Push Message"
          value={pushMessage}
          onChange={onPushMessageChange}
          placeholder="Notification Placeholder"
          errorMessage={errors?.pushMessage?.join(', ')}
        />
      </CheckboxGroup>

      <Checkbox
        checked={markedAsImportant}
        onCheckedChange={onMarkedAsImportantChange}
        aria-labelledby={markedImportantLabelId}
        aria-describedby={markedImportantDescriberId}
      >
        <Flex column alignStart>
          <CheckboxLabel
            id={markedImportantLabelId}
            htmlFor={markedImportantCheckboxId}
            label="Mark as Important"
          />
        </Flex>
      </Checkbox>
      <Text
        as="label"
        className={{ Caption: true, gray60: true }}
        id={markedImportantDescriberId}
      >
        This will highlight the communication in the Notification Center
      </Text>
    </CheckboxGroup>
  );
}

type CheckboxGroupProps = {
  label: string;
  name: keyof CheckedChannelsState;
  icon?: React.ReactNode;
  children: React.ReactNode;
};

function CheckboxGroup({ label, name, children, icon }: CheckboxGroupProps) {
  const { checked, toggleChecked } = useChannelSelectionContext();
  const isChecked = checked(name);
  const checkboxId = useUniqueId();

  const labelId = `${checkboxId}__label`;
  return (
    <>
      <Checkbox
        id={checkboxId}
        checked={isChecked}
        aria-labelledby={labelId}
        onCheckedChange={() => toggleChecked(name)}
      >
        <CheckboxLabel htmlFor={checkboxId} id={labelId}>
          {icon}
          {label}
        </CheckboxLabel>
      </Checkbox>
      {isChecked && (
        <div className={styles.checkboxGroupContainer}>{children}</div>
      )}
    </>
  );
}
