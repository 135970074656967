import { useEffect, useRef } from 'react';

export const useHasChanged = <T extends unknown>(
  value: T,
  compare?: (a?: T, b?: T) => boolean
): boolean => {
  const prevVal = usePrevious(value);

  return compare ? compare(value, prevVal) : prevVal !== value;
};

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
