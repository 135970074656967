import camelcaseKeys from 'camelcase-keys';
import {
  JourneyExecutionMetrics,
  StepMetrics,
  Steps,
} from 'models/journeys/journey';
import { request } from './api-shared';
import { NotFoundError } from './Errors/NotFoundError';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

type ServerJourneyExecutionMetrics = JourneyExecutionMetrics & {
  stepMetrics: [StepMetrics<keyof Steps>];
};

export const fetchJourneyMetrics = async (props: {
  programId: number;
  journeyId: number;
  graphId: number;
}): Promise<JourneyExecutionMetrics> => {
  const { programId, journeyId, graphId } = props;
  try {
    const response = await request(
      `${apiRoot}/samba/programs/${programId}/journeys/${journeyId}/graphs/${graphId}/metrics`
    );

    if (response.status === 200) {
      return response
        .json()
        .then(({ data }) => camelcaseKeys(data, { deep: true }))
        .then(deserializeJourneyMetrics);
    }
    throw new Error('Server error');
  } catch (e) {
    if (e instanceof NotFoundError) {
      throw new Error(
        "Journey doesn't exist or the user does not have access to it."
      );
    }

    throw e;
  }
};

const deserializeJourneyMetrics = (
  data: ServerJourneyExecutionMetrics
): JourneyExecutionMetrics => {
  return {
    ...data,
    stepMetrics: data.stepMetrics.reduce(
      (acc: Record<string, StepMetrics<keyof Steps>>, sm) => {
        acc[sm.stepId] = sm;
        return acc;
      },
      {}
    ),
  };
};
