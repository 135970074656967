import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';

export function useDownloadsFeature(): { loading: boolean; enabled: boolean } {
  const { data } = useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Insights.ReportDownloads'
  );
  const loading = !data;
  const enabled = !!data?.value;
  const { role, roleV2 } = usePermissions();
  const hasAccess =
    ['program_admin', 'administrator', 'super_admin'].includes(role) ||
    roleV2.includes('brand_admin');
  return { enabled: enabled && hasAccess, loading };
}
