import * as React from 'react';
import * as Reach from '@reach/router';
import { useDownloadsFeature } from '../hooks/useDownloadsFeature';
import { ReportDownloads } from '../components/DownloadsList';

/**
 * Renders the download list or redirects
 */

export const DownloadsPage: React.FC<Reach.RouteComponentProps> = () => {
  const { enabled, loading } = useDownloadsFeature();
  React.useEffect(() => {
    if (!loading && !enabled) Reach.navigate('.');
  }, [enabled, loading]);
  if (!enabled) return null;
  return <ReportDownloads />;
};
