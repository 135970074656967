import React, { useState } from 'react';
import { PersonalizationInput } from 'App/Program/Editors/Publisher/Deliver/Notifications/PersonalizationInput';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Caption, Text } from 'DesignSystem/Typography';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { useUniqueId } from 'hooks/useUniqueId';
import {
  Avatar,
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  Icon,
} from '@socialchorus/shared-ui-components';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { useDebounce } from 'hooks/useDebounce';
import { useProgram } from 'contexts/program';
import { InfiniteScrollList } from 'shared/InfiniteList';
import { EmailSenderAlias } from 'models/publisher/settings';
import { EmailAlias } from 'models/email-alias';
import { ProgramOrAuthor, useAuthorsList } from 'hooks/useAuthorsList';
import styles from './inputs.module.css';

type TextInputFieldProps = {
  value: string;
  label: string;
  placeholder?: string;
  inputPrefixText?: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
};

const maxSize = MAX_NOTIFICATION_LENGTH;

export function TextInputField({
  value,
  label,
  placeholder,
  inputPrefixText,
  onChange,
  disabled = false,
  errorMessage,
  id,
}: TextInputFieldProps): JSX.Element {
  const generatedId = useUniqueId();
  const elId = id ?? generatedId;
  return (
    <Flex alignStart column>
      <Label
        htmlFor={elId}
        id={`${elId}__label`}
        style={{ paddingBottom: '4px' }}
      >
        {label}
      </Label>
      <PersonalizationInput
        errorMessage={!!errorMessage}
        inputPrefixText={inputPrefixText}
        maxSize={maxSize}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        id={elId}
      />
      <Flex className={styles.errorsContainer} spread>
        <Flex>
          {errorMessage && (
            <div className={styles.error} role="alert">
              {errorMessage}
            </div>
          )}
        </Flex>
        <Caption>
          {value.length} / {maxSize} characters
        </Caption>
      </Flex>
    </Flex>
  );
}

export type LabelProps = Omit<
  React.ComponentPropsWithoutRef<'label'>,
  'className'
>;
export function Label({ children, ...otherProps }: LabelProps): JSX.Element {
  return (
    <Text
      as="label"
      className={{
        Body: true,
        Semibold: true,
      }}
      {...otherProps}
    >
      {children ? <span>{children}</span> : null}
    </Text>
  );
}

function emailAliasLabel(
  alias: Pick<EmailAlias, 'default' | 'senderEmail' | 'senderName'>
): string {
  return alias.default
    ? `Default - ${alias.senderName} <${alias.senderEmail}>`
    : `${alias.senderName} <${alias.senderEmail}>`;
}

export function EmailSenderAliasCombobox({
  emailAlias,
  onAliasSelect,
  disabled = false,
}: {
  emailAlias: EmailSenderAlias | undefined;
  onAliasSelect: (emailAlias: EmailSenderAlias) => void;
  disabled?: boolean;
}): JSX.Element {
  const { id: programId } = useProgram();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const {
    isLoading,
    data: emailAliasData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    meta,
  } = useEmailAliasesInfiniteQuery({
    programId,
    search: useDebounce(query),
    status: ['active'],
    perPage: 25,
  });

  const id = useUniqueId();
  const labelId = `${id}__label`;
  return (
    <>
      <div>
        <Label id={labelId} htmlFor={id}>
          Email Alias
        </Label>
      </div>
      <Combobox
        autoSelect
        autoComplete="none"
        id={id}
        aria-labelledby={labelId}
        placeholder="Select an email alias"
        value={query}
        onChange={(e) => setQuery(e)}
        disabled={disabled}
        leftWidget={<Icon size={24}>search</Icon>}
        defaultValue={
          emailAlias
            ? emailAliasLabel({ default: false, ...emailAlias })
            : undefined
        }
        popoverAsChild
      >
        <ComboboxPopover className={styles.comboboxPopover} gutter={4}>
          <InfiniteScrollList
            onEnd={fetchNextPage}
            isLoadingMore={isLoading || isFetchingNextPage}
            isReachingEnd={!hasNextPage}
            itemCount={meta?.totalRecords ?? 0}
          >
            {emailAliasData?.map((emailAliasItem) => (
              <ComboboxItem
                key={emailAliasItem.id}
                selectValueOnClick={() => {
                  onAliasSelect(emailAliasItem);
                  return false;
                }}
                value={emailAliasLabel(emailAliasItem)}
              >
                {emailAliasLabel(emailAliasItem)}
              </ComboboxItem>
            ))}
          </InfiniteScrollList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
}

export function AuthorCombobox({
  author,
  onAuthorSelect,
  disabled = false,
}: {
  author: ProgramOrAuthor | undefined;
  onAuthorSelect: (author: ProgramOrAuthor) => void;
  disabled?: boolean;
}): JSX.Element {
  const [query, setQuery] = useState<string | undefined>(undefined);
  const {
    isLoading,
    data: authorData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    meta,
  } = useAuthorsList(useDebounce(query) ?? '', false);
  const itemCount = Math.max(authorData.length, meta?.totalRecords ?? 0);
  const id = useUniqueId();
  const labelId = `${id}__label`;
  return (
    <>
      <div>
        <Label id={labelId} htmlFor={id}>
          Author
        </Label>
      </div>
      <Combobox
        autoSelect
        id={id}
        aria-labelledby={labelId}
        autoComplete="none"
        placeholder="Select an author"
        value={query}
        onChange={(e) => setQuery(e)}
        disabled={disabled}
        defaultValue={author ? author.displayName : undefined}
        leftWidget={
          <Avatar size="small" shape="circle" imgSrc={author?.avatarUrl} />
        }
        popoverAsChild
      >
        <ComboboxPopover className={styles.comboboxPopover} gutter={4}>
          <InfiniteScrollList
            onEnd={fetchNextPage}
            isLoadingMore={isLoading || isFetchingNextPage}
            isReachingEnd={!hasNextPage}
            itemCount={itemCount}
          >
            {authorData?.map((authorItem) => (
              <ComboboxItem
                key={authorItem.authorAliasId}
                selectValueOnClick={() => {
                  onAuthorSelect(authorItem);
                  return false;
                }}
                leftWidget={
                  <Avatar
                    size="small"
                    shape="circle"
                    imgSrc={authorItem.avatarUrl}
                  />
                }
                value={authorItem.displayName}
              >
                {authorItem.displayName}
              </ComboboxItem>
            ))}
          </InfiniteScrollList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
}
