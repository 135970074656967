import React, { useState } from 'react';
import cx from 'classnames';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { MailSquared } from 'shared/icons';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { SendTestCampaignModal } from 'App/Program/Editors/Publisher/NewReview/ContentPreviewSection/SendTestCampaignModal';
import { usePublisher } from 'contexts/publisher';
import { Targets } from 'models/donkey';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import styles from './send-test-campaign.module.css';

export const SendTestCampaign: React.FC = () => {
  const {
    post: { blocks },
    status,
    isProcessing,
  } = usePublisher();
  const {
    contentPermissions: { canEdit },
    settings: {
      deliveryChannels: { email: hasEmailChannel, push: hasPushChannel },
    },
  } = useSettings();

  const [showTestCampaignModal, setShowTestCampaignModal] = useState(false);
  const visibleBlocksForEmail = blocks.filter(
    (block) =>
      block?.target === undefined ||
      !block?.target.excluded.includes(Targets.email)
  ).length;

  if (visibleBlocksForEmail < 1) {
    return null;
  }
  return (
    <>
      {canEdit && (
        <SendTestCampaignButton
          disabled={blocks.length === 0 || isProcessing || status.isSaving}
          processing={isProcessing}
          onClick={() => setShowTestCampaignModal(!showTestCampaignModal)}
        />
      )}
      {showTestCampaignModal && (
        <SendTestCampaignModal
          hasEmailChannel={hasEmailChannel}
          hasPushChannel={hasPushChannel}
          toggleShow={() => setShowTestCampaignModal(!showTestCampaignModal)}
        />
      )}
    </>
  );
};

const SendTestCampaignButton: React.FC<{
  disabled: boolean;
  processing: boolean;
  onClick: () => void;
}> = ({ disabled, processing, onClick }) => {
  const button = (
    <Button
      data-testid="preview-send-test-campaign-button"
      className={styles.Button}
      compact
      secondary
      disabled={disabled}
      onClick={onClick}
      label={
        <>
          <MailSquared />
          <span className={styles.ButtonText}>Send Test Campaign</span>
        </>
      }
    />
  );

  return (
    <Box
      margin={[16, 0, 0, 0]}
      className={cx({
        [styles.processing]: processing,
      })}
    >
      {processing ? (
        <HoverTooltip
          align="left"
          content={
            <Tooltip description="This button is disabled until image processing has completed." />
          }
          icon={button}
        />
      ) : (
        button
      )}
    </Box>
  );
};
