import React, { useEffect, useState } from 'react';
import { useProgram } from 'contexts/program';
import { Box } from 'DesignSystem/Components';
import { Body, color, FieldLabel, Subheading } from 'DesignSystem/Typography';
import { Checkbox } from 'shared/Checkbox';
import { useSendTestEmail } from 'hooks/publisher';
import { usePublisher } from 'contexts/publisher';
import { useCurrentUserQuery } from 'hooks/user';
import { Pills } from 'DesignSystem/Components/Pills';
import { User } from 'models/user';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { FormModalSectioned } from 'shared/FormModalSectioned';
import { useSendTestPush } from 'hooks/useSendTestPush';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFlashMessage } from 'contexts/flasher';
import { ExclamationTriangleOutlined } from 'shared/icons';
import { NotificationField } from '../../../Deliver/Notifications/NotificationField';
import {
  PREVIEW_TEXT_PLACEHOLDER,
  TEXT_PLACEHOLDER,
  useNotifications,
} from '../../../Deliver/Notifications/useNotifications';
import { SelectUsers } from './SelectUsers';
import styles from './send-test-campaign-modal.module.css';
import { channelIcons } from '../../../Deliver/Channels/ChangeChannels';

const EMAIL_PREFIX = '[Test Email]';
const PUSH_PREFIX = '[Test Push]';

const ErrorBox: React.FC<{ text: string; marginTop: number }> = ({
  text,
  marginTop,
}) => (
  <Box style={{ display: 'flex' }} margin={[marginTop, 0, 0, 0]}>
    <Box margin={['auto', 9, 'auto', 0]}>
      <ExclamationTriangleOutlined />
    </Box>
    <Body color={color.redFull}>{text}</Body>
  </Box>
);

export const SendTestCampaignModal: React.FC<{
  hasPushChannel: boolean;
  hasEmailChannel: boolean;
  toggleShow: () => void;
}> = ({ hasPushChannel, hasEmailChannel, toggleShow }) => {
  const { firstNotification } = useNotifications();
  const { post } = usePublisher();
  const currentUser = useCurrentUserQuery().data;
  const { setFlashMessage } = useFlashMessage();

  const [localEmailText, setLocalEmailText] = useState(firstNotification.text);
  const [localEmailPreview, setLocalEmailPreview] = useState(
    firstNotification.previewText || ''
  );
  const [localPushText, setLocalPushText] = useState(
    firstNotification.pushText || ''
  );

  const [useEmailChannel, setUseEmailChannel] = useState(hasEmailChannel);
  const [usePushChannel, setUsePushChannel] = useState(hasPushChannel);

  const [users, setUsers] = React.useState<User[]>([]);
  const { id: programId } = useProgram();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );

  const { sendEmailRequest } = useSendTestEmail(
    localEmailText,
    localEmailPreview,
    post,
    users.map(({ id }) => id),
    preferOutlook365
  );

  const { sendPushRequest } = useSendTestPush(
    localPushText,
    '', // push messages do not have a preview
    post,
    users.map(({ id }) => id)
  );

  useEffect(() => {
    if (currentUser) {
      setUsers([currentUser]);
    }
  }, [currentUser]);

  const renderPill = ({
    id,
    avatarUrl,
    firstName,
    lastName,
    displayName,
  }: User) => (
    <div style={{ display: 'flex' }}>
      {avatarUrl && (
        <img
          className={styles.UserAvatar}
          alt={`user-${id}-avatar`}
          src={avatarUrl}
        />
      )}
      <span className={styles.UserName}>
        {displayName || `${firstName} ${lastName}`}
      </span>
    </div>
  );

  const onSubmit = () => {
    const requests = [
      useEmailChannel && sendEmailRequest(),
      usePushChannel && sendPushRequest(),
    ].filter(Boolean) as Array<Promise<void>>;

    Promise.all(requests).then(() => {
      if (!users || users.length === 0) {
        return;
      }
      let additionalMessage = '';
      if (users.length > 1) {
        additionalMessage = ` and ${users.length - 1} other recipient(s)`;
      }
      const { firstName, displayName } = users[0];
      const message = `Test messages sent to ${
        firstName || displayName
      }${additionalMessage}.`;
      setFlashMessage({
        severity: 'info',
        message,
      });
    });

    toggleShow();
  };

  const emailOnly = !!useFeatureFlag('emailOnly');

  const emailValid =
    !useEmailChannel ||
    (localEmailText.length !== 0 &&
      localEmailText.length <= MAX_NOTIFICATION_LENGTH &&
      localEmailPreview.length <= MAX_NOTIFICATION_LENGTH);

  const pushValid =
    !usePushChannel ||
    (localPushText.length !== 0 &&
      localPushText.length <= MAX_NOTIFICATION_LENGTH);

  const isBothChannelsNotSelected = !useEmailChannel && !usePushChannel;
  const isNoUsersSelected = users.length === 0;
  const userLimitReached = users.length > 100;
  const disableForm =
    isNoUsersSelected ||
    !emailValid ||
    !pushValid ||
    isBothChannelsNotSelected ||
    userLimitReached;

  return (
    <FormModalSectioned
      entityText="test campaign"
      actionText="send"
      submitLabel="Send"
      disabled={disableForm}
      onSubmit={onSubmit}
      width={680}
      innerPadding={0}
      onCancel={toggleShow}
    >
      <Box padding={8}>
        <Subheading bold>Channels</Subheading>
        <Box margin={[8, 0, 0, 0]} style={{ display: 'flex' }}>
          <Checkbox
            checked={useEmailChannel}
            onChange={setUseEmailChannel}
            disabledClassName={styles.CheckingDisabled}
            labelClassName={styles.CheckboxLabel}
            label={
              <Body>
                <span className={styles.Label}>
                  <span className={styles.Icon}>{channelIcons('email')}</span>
                  <span>Email</span>
                </span>
              </Body>
            }
          />
        </Box>
        {useEmailChannel && (
          <Box margin={[0, 0, 0, 35]}>
            <Box margin={[16, 0, 0, 0]}>
              <FieldLabel>Subject Line</FieldLabel>
              <NotificationField
                field={localEmailText}
                inputPrefixText={EMAIL_PREFIX}
                maxSize={MAX_NOTIFICATION_LENGTH - EMAIL_PREFIX.length}
                onChange={setLocalEmailText}
                placeholder={TEXT_PLACEHOLDER}
                fieldName=""
              />
            </Box>
            <Box margin={[-12, 0, 0, 0]}>
              <FieldLabel>Preview</FieldLabel>
              <NotificationField
                field={localEmailPreview}
                onChange={setLocalEmailPreview}
                placeholder={PREVIEW_TEXT_PLACEHOLDER}
                fieldName=""
              />
            </Box>
          </Box>
        )}
        {!emailOnly && (
          <>
            <Box margin={[8, 0, 0, 0]} style={{ display: 'flex' }}>
              <Checkbox
                checked={usePushChannel}
                onChange={setUsePushChannel}
                disabledClassName={styles.CheckingDisabled}
                labelClassName={styles.CheckboxLabel}
                label={
                  <Body>
                    <span className={styles.Label}>
                      <span className={styles.Icon}>
                        {channelIcons('push')}
                      </span>
                      <span>Push Notification</span>
                    </span>
                  </Body>
                }
              />
            </Box>

            {usePushChannel && (
              <Box margin={[0, 0, 0, 35]}>
                <Box margin={[16, 0, 0, 0]}>
                  <FieldLabel>Message</FieldLabel>
                  <NotificationField
                    field={localPushText}
                    inputPrefixText={PUSH_PREFIX}
                    maxSize={MAX_NOTIFICATION_LENGTH - PUSH_PREFIX.length}
                    onChange={setLocalPushText}
                    fieldName=""
                    placeholder={TEXT_PLACEHOLDER}
                  />
                </Box>
              </Box>
            )}
          </>
        )}

        {isBothChannelsNotSelected && (
          <ErrorBox
            text="Please select at least one channel to send test campaign"
            marginTop={14}
          />
        )}
        <Box margin={[12, 0, 0, 0]}>
          <Subheading bold>Recipients</Subheading>
          <Box margin={[8, 0, 0, 0]}>
            <SelectUsers selectedUsers={users} onUserChange={setUsers} />
            {userLimitReached && (
              <ErrorBox
                text={`Limit exceeded: ${users.length} recipient selected. Please select no more than 100 recipients`}
                marginTop={14}
              />
            )}
            <Pills
              values={users}
              render={renderPill}
              onClose={({ id }) =>
                setUsers(users.filter(({ id: uId }) => id !== uId))
              }
              padding={[0, 16, 0, 4]}
            />
          </Box>
        </Box>
        {isNoUsersSelected && (
          <ErrorBox
            text="Please select at least one recipient to send test campaign"
            marginTop={2}
          />
        )}
      </Box>
    </FormModalSectioned>
  );
};
